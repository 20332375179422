<template>
  <v-container>
    <v-card rounded-pill width="100%" class="mx-auto pa-1 mb-1" elevation="2" >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 200px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다.."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
        />
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
        />
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          새로고침
        </v-btn>
      </v-layout>
    </v-card>
      <v-tabs color="#3a4f3f" centered  mt-1>
        <v-tab>
          <v-icon left large color="#3a4f3f">
            <!-- mdi-account-edit -->
          </v-icon>
          <span class="tab_text_green"> 일별 증체량 </span>
        </v-tab>
        <v-tab width="100px"> 
          <v-icon left large color="#88191a">
            <!-- mdi-account-key -->
          </v-icon>
          <span class="tab_text_red">적정 온도 </span>
        </v-tab>
  
        <v-tab-item>
            <v-container>
              <WeightPerDay 
                ref="weightperday"
              />
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
              <GuideRoomTemp />
            </v-container>
        </v-tab-item>
      </v-tabs>

 </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import WeightPerDay from "./child/WeightPerDay.vue";
import GuideRoomTemp from "./child/GuideRoomTemp.vue";

export default {
  name: 'BaseData',
  components: {
    WeightPerDay,
    GuideRoomTemp,
  },
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    this.comboCompanies();
    this.comboFarms();

    let cookie_info = VueCookies.get("basedata_info");
      if (cookie_info) {
        // console.log("쿠키",cookie_info);
        setTimeout( () => {
                            this.company = cookie_info["company"] || {};
                            this.farm = cookie_info.farm || {};
                            this.refreshList();
                          }, 1000);
      } else {
        setTimeout( () => {
                            this.farm = this.farmItems[0];
                            this.company = {code: this.farm.company_cd, name: this.farm.company_name};
                            this.refreshList()
                      }, 1000);
      }
  },
  data () {
    return {
      tabs: null,

      loading: false,
      company : {},
      companyItems : [],
      farm : {},
      farmItems : [],
    }
  },
  methods: {

    refreshList() {
      if  (!this.farm || !this.farm.code ) {
        alert("농장/사업장을 선택하세요");
        this.$refs.farm.focus();
        return;
      }
      this.$refs.weightperday.listWeight({
        company_cd: this.company.code,
        farm_cd: this.farm.code,
        });
      let cookie_info = { company: this.company, farm: this.farm };
      VueCookies.set("basedata_info" ,cookie_info);
    }, 
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
      this.sensor = {};
    },
    comboFarms() {
      Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.refreshList();
    },

  }  // -----------methods 

}
</script>
<style lang="scss" scoped>
.tab_text_green{
  color:#3a4f3f;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.tab_text_red{
  color:#88191a;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
</style>
