<template>
  <v-container >
    <v-layout row>
      <v-sheet width="140px" height="auto" class="ma-1"
        v-for="(items, i) in this.itemsList"
            :key="i"
            >
        <v-data-table  dense height="672"
          fixed-header
          hide-default-footer
          :items-per-page= "50"
          :loading="loadingTable" 
          :headers="headers"
          :items="items"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          class="elevation-3"
          item-key="ageday"
          single-select
          :item-class="itemRowBackground"
          sort-by="ageday"
          @click:row="selectRow"
        >
        </v-data-table>
      </v-sheet>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
// import Common from "@/utils/custom/common.js";

export default {
  name: 'WeightPerDay',

  created() {
  },

  data() {
    return {
      itemsList: [],
      items: Array,
      headers: [
        { text: '일령', align: 'right', value: 'ageday', sortable: false, width: "50%", class: 'green darken-4 white--text',divider: true },
        { text: 'Kg', align: 'right', value: 'weight', sortable: false, width: "70%",class: 'green darken-4 white--text' },
      ],
      loadingTable: false,
      routedAge: "",
    }
  },
  methods: {
    listWeight(searchFarm) {
      if (!searchFarm.company_cd) return;

      this.$store.commit("resMessage","");
      this.loadingTable = true;
      Apis.listWeightPerDay({
        company_cd: searchFarm.company_cd,
        farm_cd: searchFarm.farm_cd,
      } ,(res) => {  // 정상처리
        this.itemsList = this.splitList(res.data,20);
        this.$store.commit("resMessage",res.message);
        this.loadingTable = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listWeightPerDay 호출 오류",err);
        this.$router.push("/");
        this.loadingTable = false;
      }) 
    }, // -----------listCompanies
    selectRow(item,row) {
      row.select(true);
      this.selectedAge = item;
      this.routedAge = "";
    },
    itemRowBackground(item) {
      return item.ageday == this.routedAge  ? 'selected' : '' ;
    },
    splitList(arr, size) {
      var i, j, temparray = [], chunk = size;
      for (i = 0, j = arr.length; i < j; i += chunk) {
        temparray.push(arr.slice(i, i + chunk));
      }
      return temparray;

    },

  },  // -----------methods 
}
</script>

<style lang="scss" scoped>
.normal-btn{
  vertical-align: middle !important;
  color:white ;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>

